import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"

const Header = () => (
  <header>
    <Navbar bg="light" variant="light" expand="lg">
      <Navbar.Brand href="/">
        <StaticImage
          src="../images/moussadrametennis-logo-2021-512.png"
          width={80}
          className="d-inline-block align-top"
          alt="Moussa Drame Tenis logo 2021"
          style={{ marginLeft: `1.45rem` }}
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav as="ul" className="mr-auto">
          <Nav.Item as="li">
            <Link to="/" className="nav-link" activeClassName="active">
              Home
            </Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Link
              to="/#services-section"
              className="nav-link"
              activeClassName="active"
            >
              Services
            </Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Link
              to="/promotions"
              className="nav-link"
              activeClassName="active"
            >
              Promotions
            </Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Link
              to="/#contact-us"
              className="nav-link"
              activeClassName="active"
            >
              Contact Us
            </Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Link
              to="/sign-up-now"
              className="nav-link"
              activeClassName="active"
            >
              Sign Up Now
            </Link>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </header>
)

export default Header
