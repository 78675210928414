import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faInstagramSquare,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons"
import * as footerStyles from "../styles/footer.module.css"

const Footer = () => {
  return (
    <>
      <footer>
        <hr />
        <div className={footerStyles.socialMedia}>
          <h4>
            Social media:
            </h4>
            <a href="https://twitter.com/moussadrame10s/">
              <FontAwesomeIcon icon={faTwitter} size="2x" width="2rem" />
            </a>
            <a href="https://www.instagram.com/moussadrametennis/">
              <FontAwesomeIcon
                icon={faInstagramSquare}
                size="2x"
                width="2rem"
              />
            </a>
          
        </div>
        <hr />
        <div className={footerStyles.container}>
          <small>
            Copyright {` `} © 2017 - {new Date().getFullYear()},{` `}
            Moussa Drame Tennis, Inc. All Rights Reserved.
          </small>
          <small>
            Built it by:
            <a href="https://xpaso.com"> Xpaso LLC</a>
          </small>
        </div>
      </footer>
    </>
  )
}

export default Footer
